(function ($) {
  //Fixed Menu
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('.site-header').addClass("fixed-menu").fadeIn();
    } else {
      $('.site-header').removeClass("fixed-menu");
    }
  });

  //Slider sidebar
  $('.sidebar-section-03 .panel-grid-cell').bxSlider({
    randomStart: true,
    easing: 'ease-out',
    pager: false
  });

})(jQuery);
